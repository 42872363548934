import React, { MutableRefObject, useCallback, useMemo, useState } from "react";
import { BaseTableRow, Table, TableProps } from "../Table/Table";
import { Collapse, Stack, Typography } from "@mui/material";
import { ControlledQuarterSelect } from "../../../investments/v2/QuarterSelect/ControlledQuarterSelect";
import { IconButton } from "../IconButton/IconButton";
import {
  DocumentsIcon,
  DownloadIcon,
  DownloadTableIcon,
  Filter,
  FilterOpened,
} from "../Icon/Icon";
import { GridRowId, useGridApiRef } from "@mui/x-data-grid-premium";
import { Box } from "../Box/Box";
import { match } from "ts-pattern";
import {
  BaseDocumentsTableRow,
  DocumentsTable,
  DocumentsTableProps,
} from "../DocumentsTable/DocumentsTable";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

type DocumentsTableKind<R extends BaseDocumentsTableRow> = {
  kind: "documents-table";
} & DocumentsTableProps<R>;

type TableKind<R extends BaseTableRow> = {
  kind: "table";
} & Omit<TableProps<R>, "apiRef">;

type DiscriminatedTableKind<R extends BaseDocumentsTableRow> =
  | DocumentsTableKind<R>
  | TableKind<R>;

type Props<R extends BaseDocumentsTableRow> = DiscriminatedTableKind<R> & {
  title: string;
  description?: string;
  loading?: boolean;
  loaderComponent?: React.ReactNode;
  filtersComponent?: React.ReactNode;
  quarterSelect?: boolean;
  downloadCsv?: boolean;
  downloadExcel?: boolean;
  exportHidden?: boolean;
  exportColumns?: Array<keyof R>;
  exportLink?: string;
  customRightComponent?: React.ReactNode;
  excelGetRowsToExport?: (
    apiRef: MutableRefObject<GridApiPremium>,
  ) => GridRowId[];
  renderAfterTitle?: React.ReactNode;
};

/**
 * A wrapper component for the `Table` that adds a title
 * and manages all the top-right actions/filters.
 */
export const PageTable = <R extends BaseTableRow>({
  title,
  description,
  kind = "table",
  loading,
  loaderComponent,
  filtersComponent,
  quarterSelect,
  downloadCsv,
  downloadExcel,
  exportHidden,
  exportColumns,
  exportLink,
  customRightComponent,
  excelGetRowsToExport,
  renderAfterTitle,
  ...tableProps
}: Props<R>) => {
  const apiRef = useGridApiRef();
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleToggleFilters = useCallback(() => {
    setFiltersOpen((value) => !value);
  }, []);

  const handleDownloadCsv = useCallback(() => {
    if (!apiRef.current) return;

    apiRef.current.exportDataAsCsv({
      allColumns: exportHidden,
      fields: exportColumns as Array<string> | undefined,
      utf8WithBom: true,
      escapeFormulas: false,
    });
  }, [apiRef, exportHidden, exportColumns]);

  const handleDownloadExcel = useCallback(() => {
    if (!apiRef.current) return;

    apiRef.current.exportDataAsExcel({
      allColumns: exportHidden,
      fields: exportColumns as Array<string> | undefined,
      getRowsToExport: excelGetRowsToExport
        ? () => excelGetRowsToExport(apiRef)
        : undefined,
      escapeFormulas: false,
    });
  }, [apiRef, exportHidden, exportColumns, excelGetRowsToExport]);

  const showActions =
    filtersComponent ||
    downloadCsv ||
    downloadExcel ||
    quarterSelect ||
    customRightComponent;

  const ComputedTableComponent = useMemo(
    () =>
      match(kind)
        .with("table", () => Table)
        .with("documents-table", () => DocumentsTable)
        .exhaustive(),
    [kind],
  );

  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          sx={(theme) => ({
            flexWrap: "wrap",

            [theme.breakpoints.up("md")]: {
              flexWrap: "nowrap",
            },
          })}
        >
          <Box flex="0 0 auto">
            <Typography variant="h1">{title}</Typography>
          </Box>

          {showActions ? (
            <Box flex="1 1 100%">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                maxWidth="100%"
              >
                {filtersComponent ? (
                  <Box flex="0 0 auto">
                    <IconButton
                      Icon={filtersOpen ? FilterOpened : Filter}
                      onClick={handleToggleFilters}
                    />
                  </Box>
                ) : undefined}

                {downloadCsv ? (
                  <Box flex="0 0 auto">
                    <IconButton
                      Icon={DownloadIcon}
                      onClick={handleDownloadCsv}
                    />
                  </Box>
                ) : undefined}

                {exportLink ? (
                  <Box flex="0 0 auto">
                    <IconButton Icon={DocumentsIcon} href={exportLink} />
                  </Box>
                ) : undefined}

                {downloadExcel ? (
                  <Box flex="0 0 auto">
                    <IconButton
                      Icon={DownloadTableIcon}
                      onClick={handleDownloadExcel}
                    />
                  </Box>
                ) : undefined}

                {quarterSelect ? (
                  <Box
                    flex={{
                      xs: "1 1 100%",
                      md: "0 0 auto",
                    }}
                    ml={1}
                  >
                    <ControlledQuarterSelect />
                  </Box>
                ) : undefined}

                {customRightComponent ? (
                  <Box
                    flex={{
                      xs: "1 1 100%",
                      md: "0 0 auto",
                    }}
                  >
                    {customRightComponent}
                  </Box>
                ) : undefined}
              </Stack>
            </Box>
          ) : undefined}
        </Stack>

        {description ? (
          <Box mt={1} maxWidth={1100}>
            <Typography>{description}</Typography>
          </Box>
        ) : undefined}

        {renderAfterTitle ? <Box mt={2}>{renderAfterTitle}</Box> : undefined}
      </Box>

      {filtersComponent ? (
        <Collapse
          in={filtersOpen}
          collapsedSize={0}
          orientation="vertical"
          timeout={150}
          sx={{ flex: "0 0 auto" }}
        >
          <Box mt={2}>{filtersComponent}</Box>
        </Collapse>
      ) : undefined}

      <Box mt={2}>
        {!loading ? (
          <ComputedTableComponent apiRef={apiRef} {...tableProps} />
        ) : undefined}

        {loading && loaderComponent ? loaderComponent : undefined}
      </Box>
    </>
  );
};
