import React, { PropsWithChildren } from "react";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Stack } from "@mui/material";
import {
  MoneyT,
  PercentSumT,
  AmountsT,
  PercentT,
  NumericT,
  LabeledTrailT,
  MoneyAddT,
  NumericAddT,
  PercentAddT,
  MoneySumT,
  MoneySubT,
  NumericSubT,
  PercentSubT,
  MoneyTimesT,
  MoneyOverT,
  isLeafTrail,
  Currency,
  NumericTimesT,
  NumericOverT,
  MoneyMinT,
  MoneyMaxT,
  MoneyToSingleCurrencyT,
  MoneyWithSingleCurrencyT,
  MoneyGenericOperationT,
  PercentPowT,
} from "./types";
import { OptionMenu } from "./components/OptionMenu";

type Props = Readonly<{
  value: MoneyT;
}>;

const showAmount = U_Show.getFromNumeralFormat("0,0.00");

const showPercent = U_Show.getFromNumeralFormat("0.0000%");

const fontFamilyMono =
  "ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace";

const fontFamilySans =
  "Bahnschrift, 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow', sans-serif-condensed, sans-serif";

const AddSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> + </span>
);

const SubSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> - </span>
);

const TimesSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> ⨉ </span>
);

const CommaSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> , </span>
);

const MinSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> ;MIN; </span>
);

const MaxSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> ;MAX; </span>
);

const EmptySymbol = () => (
  <span
    style={{
      fontFamily: fontFamilySans,
      fontSize: "1.6em",
      padding: "0.4em",
    }}
  >
    {" "}
    [ ]{" "}
  </span>
);

const PowSymbol = () => (
  <span style={{ fontFamily: fontFamilySans, fontSize: "1.6em" }}> ^ </span>
);

const CurrencyChip: React.FC<{ currency: string }> = ({ currency }) => (
  <div
    style={{
      backgroundColor: "#eee",
      marginLeft: "0.4em",
      fontSize: "0.8em",
      padding: "0.1em 0.4em",
      borderRadius: "0.3em",
      border: "1px solid",
      borderColor: "#ccc",
      fontFamily: fontFamilySans,
    }}
  >
    {currency}
  </div>
);

const MoneyAmountNode: React.FC<{ amounts: AmountsT }> = ({ amounts }) => {
  const currencies = Object.keys(amounts);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.2em",
        alignItems: "center",
      }}
    >
      {currencies.length === 0 && (
        <span style={{ fontFamily: fontFamilyMono }}>0</span>
      )}
      {currencies.length > 0 &&
        currencies.map((currency) => {
          const amount = amounts[currency as Currency];
          return (
            <div
              key={currency}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ fontFamily: fontFamilyMono }}>
                {amount !== undefined
                  ? showAmount.show(amount)
                  : `can't find an amount for currency ${currency}`}
              </div>
              <CurrencyChip currency={currency} />
            </div>
          );
        })}
    </div>
  );
};

const NumericAmountNode: React.FC<{ value: number }> = ({ value }) => (
  <div
    style={{
      padding: "0.2em",
      textAlign: "center",
      fontFamily: fontFamilyMono,
    }}
  >
    {value.toFixed(6)}
  </div>
);

const PercentAmountNode: React.FC<{ value: number }> = ({ value }) => (
  <div
    style={{
      padding: "0.2em",
      textAlign: "center",
      fontFamily: fontFamilyMono,
    }}
  >
    {showPercent.show(value / 100)}
  </div>
);

const AmountNode: React.FC<{ value: unknown }> = ({ value }) => {
  if (MoneyT.is(value)) {
    return <MoneyAmountNode amounts={value.amounts} />;
  }
  if (PercentT.is(value)) {
    return <PercentAmountNode value={value.value} />;
  }
  if (NumericT.is(value)) {
    return <NumericAmountNode value={value.value} />;
  }
  return <span>LEAF_UNKNOWN</span>;
};

const TrailNode: React.FC<{ value: { trail: unknown } }> = ({ value }) => {
  const { trail } = value;
  const [isOpen, setIsOpen] = React.useState(false);
  const label = LabeledTrailT.is(trail) ? trail.label : undefined;
  const hasLabel = label !== undefined;
  const canBeOpened = !isLeafTrail(trail) && hasLabel;
  const content =
    isLeafTrail(trail) || (canBeOpened && !isOpen) ? (
      <AmountNode value={value} />
    ) : (
      <OperationNode trail={trail} />
    );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "#aaa",
        borderRadius: "0.3em",
        margin: "0.3em",
      }}
    >
      {label && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={0.5}
          sx={{
            backgroundColor: canBeOpened ? "blueviolet" : "blue",
            fontSize: "0.8em",
            cursor: canBeOpened ? "pointer" : "default",
            color: "white",
            py: "0.2em",
            borderTopRightRadius: "0.3em",
            borderTopLeftRadius: "0.3em",
            pl: "0.4em",
            pr: "0.2em",
            fontFamily: fontFamilySans,
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {canBeOpened && (
            <>
              <ExpandLessIcon
                sx={{
                  fontSize: 18,
                  rotate: !isOpen ? "180deg" : "0deg",
                }}
              />
            </>
          )}
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              flex: 1,
            }}
          >
            {label}
          </span>
          <Box sx={{ pl: 1 }}>
            <OptionMenu value={value} />
          </Box>
        </Stack>
      )}
      {content}
    </div>
  );
};

const OperationNodeContainer: React.FC<
  PropsWithChildren<{ pad?: boolean; direction?: "row" | "column" }>
> = ({ children, pad, direction }) => (
  <div
    style={{
      display: "flex",
      flexDirection: direction ?? "row",
      padding: pad ? "0.4em" : "0",
      alignItems: "center",
      gap: "0.4em",
    }}
  >
    {children}
  </div>
);

const OperationNode: React.FC<{ trail: unknown }> = ({ trail }) => {
  const label = LabeledTrailT.is(trail) ? trail.label : undefined;
  const pad = label !== undefined;
  if (MoneyAddT.is(trail) || NumericAddT.is(trail) || PercentAddT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <TrailNode value={trail.left} /> <AddSymbol />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  if (MoneySumT.is(trail) || PercentSumT.is(trail)) {
    return trail.items.length > 0 ? (
      <OperationNodeContainer pad={pad}>
        {trail.items.map((t, i) => (
          <React.Fragment key={i}>
            {i > 0 && <AddSymbol />}
            <TrailNode value={t} />
          </React.Fragment>
        ))}
      </OperationNodeContainer>
    ) : (
      <EmptySymbol />
    );
  }

  if (MoneyGenericOperationT.is(trail)) {
    return trail.args.length > 0 ? (
      <OperationNodeContainer pad={pad}>
        {trail.args.map((t, i) => (
          <React.Fragment key={i}>
            {i > 0 && <CommaSymbol />}
            <TrailNode value={t} />
          </React.Fragment>
        ))}
      </OperationNodeContainer>
    ) : (
      <EmptySymbol />
    );
  }

  if (MoneySubT.is(trail) || NumericSubT.is(trail) || PercentSubT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <TrailNode value={trail.left} /> <SubSymbol />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  if (MoneyTimesT.is(trail) || NumericTimesT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <TrailNode value={trail.left} /> <TimesSymbol />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  if (MoneyOverT.is(trail) || NumericOverT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad} direction="column">
        <TrailNode value={trail.left} />
        <div
          style={{
            borderBottom: "2px solid",
            borderBottomColor: "#000",
            width: "100%",
          }}
        />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  if (MoneyMinT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <TrailNode value={trail.left} /> <MinSymbol />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  if (MoneyMaxT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <TrailNode value={trail.left} /> <MaxSymbol />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  if (MoneyToSingleCurrencyT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <CurrencyChip currency={trail.currency} />
        <TrailNode value={trail.value} />
      </OperationNodeContainer>
    );
  }

  if (MoneyWithSingleCurrencyT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <CurrencyChip currency={trail.currency} />
        <TrailNode value={trail.value} />
      </OperationNodeContainer>
    );
  }

  if (PercentPowT.is(trail)) {
    return (
      <OperationNodeContainer pad={pad}>
        <TrailNode value={trail.left} /> <PowSymbol />
        <TrailNode value={trail.right} />
      </OperationNodeContainer>
    );
  }

  return (
    <OperationNodeContainer pad={pad}>
      Unknown[${JSON.stringify(trail)}]
    </OperationNodeContainer>
  );
};

export const AuditTrailFormula: React.FC<Props> = ({ value }) => {
  return (
    <div
      style={{
        padding: 20,
        overflow: "scroll",
        display: "flex",
        justifyItems: "flex-start",
      }}
    >
      <TrailNode value={value} />
    </div>
  );
};
